import { format } from 'date-fns';
import debounce from 'lodash/debounce';
import { useMemo } from 'react';
import type { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { match } from 'ts-pattern';

import { type ModelsLearningGroup } from '@lp-lib/api-service-client/public';

import { apiService } from '../../../../services/api-service';
import { buildReactSelectStyles } from '../../../../utils/react-select';

export type LearningGroupOption = {
  kind: 'existing';
  group: ModelsLearningGroup;
  value: string;
};

interface LearningGroupMultiSelectProps {
  options: LearningGroupOption[];
  onChange: (val: LearningGroupOption[]) => void;
  placeholder?: string;
  disabled?: boolean;
  badgeColor?: string;
  badgeTextColor?: string;
}

async function loadAllGroups(): Promise<LearningGroupOption[]> {
  try {
    const resp = await apiService.learning.getUserCreatedGroups();
    const groups = resp.data as ModelsLearningGroup[];
    return groups.map((g) => ({
      kind: 'existing' as const,
      group: g,
      value: g.id,
    }));
  } catch {
    return [];
  }
}

async function searchGroupsByName(
  query: string
): Promise<LearningGroupOption[]> {
  try {
    const resp = await apiService.learning.searchGroupsByName(query);
    const groups = resp.data.groups as ModelsLearningGroup[];
    return groups.map((g) => ({
      kind: 'existing' as const,
      group: g,
      value: g.id,
    }));
  } catch {
    return [];
  }
}

async function loadGroups(inputValue: string): Promise<LearningGroupOption[]> {
  if (!inputValue) {
    return await loadAllGroups();
  } else {
    return await searchGroupsByName(inputValue);
  }
}

const debouncedLoad = debounce(
  (inputValue: string, callback: (val: LearningGroupOption[]) => void) => {
    loadGroups(inputValue)
      .then((results) => callback(results))
      .catch(() => callback([]));
  },
  200
);

export function LearningGroupMultiSelect(props: LearningGroupMultiSelectProps) {
  const {
    options,
    onChange,
    placeholder,
    disabled,
    badgeColor,
    badgeTextColor,
  } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<LearningGroupOption, true>({
        override: {
          control: { minHeight: 48 },
          multiValue: () => ({
            background: badgeColor || '#01ACC4',
          }),
          multiValueLabel: {
            color: badgeTextColor || 'white',
          },
          multiValueRemove: {
            color: `${badgeTextColor || 'white'} !important`,
          },
        },
      }),
    [badgeColor, badgeTextColor]
  );

  function loadOptions(
    inputValue: string,
    callback: (options: LearningGroupOption[]) => void
  ): void {
    debouncedLoad(inputValue, callback);
  }

  function getOptionValue(option: LearningGroupOption) {
    return option.group.id;
  }

  function formatOptionLabel(
    option: LearningGroupOption,
    { context }: { context: 'menu' | 'value' }
  ) {
    return match(option)
      .with({ kind: 'existing' }, (o) => {
        const createdDate = format(new Date(o.group.createdAt), 'MMM d, yyyy');

        if (context === 'menu') {
          return (
            <div className='flex flex-col'>
              <span className='text-white text-sms font-normal'>
                {o.group.name}
              </span>
              <span className='text-3xs text-white/80'>
                Created on {createdDate}
              </span>
            </div>
          );
        }

        return (
          <span className='text-white text-xs font-normal'>{o.group.name}</span>
        );
      })
      .exhaustive();
  }

  function handleChange(newValue: MultiValue<LearningGroupOption>) {
    onChange(newValue.slice()); // convert from readonly array
  }

  return (
    <AsyncSelect<LearningGroupOption, true>
      isMulti
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
      placeholder={placeholder}
      value={options}
      onChange={handleChange}
      styles={styles}
      isDisabled={disabled}
      classNamePrefix='select-box-v2'
      noOptionsMessage={() => 'No matching groups'}
    />
  );
}
